@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

@font-face {
	font-family: 'TrixieRoughPro';
	src: url('../../dist/font/TrixieRoughPro-Heavy.woff2') format('woff2'),
	url('../../dist/font/TrixieRoughPro-Heavy.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Anzeigen Grotesk D';
	src: url('../../dist/font/AnzeigenGroD.woff2') format('woff2'),
			url('../../dist/font/AnzeigenGroD.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: 'TrixieRoughPro';
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
}

.texture-overlay{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	mix-blend-mode: multiply;
	pointer-events: none;
	z-index: 999999;
	display: none;
}

.textured{
	position: relative;
	&::after{
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		background-image: url('../../dist/img/texture-paper.jpg');
		object-fit: cover;
		top: 0;
		left: 0;
		mix-blend-mode: multiply;
		pointer-events: none;
		z-index: 9999;
	}
}

h1,h2,h3{
	font-family: 'Anzeigen Grotesk D';
}

h1,h2,h3,p{
	margin: 0;
	line-height: 1.2;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
} 

.btn{
	font-size: 15px;
	text-transform: uppercase;
	padding: 8px 12px;
	display: inline-block;
	background-color: $red;
	color: $white;
}

.mobile{
	display: block;
}

.desktop{
	display: none;
}
@include tablet-up{
	.mobile{
		display: none;
	}
	
	.desktop{
		display: block;
	}
}

header {
	padding: 20px 0;
	.container{

		.top{
			@include flex ( column-reverse, initial, initial);
			img.heading {
				margin-bottom: 20px
			}
			.side{
				@include flex ( row-reverse, space-between, initial);
				margin-bottom: 10px;
				.inner-top{
					@include flex ( row-reverse, space-between, initial);
					width: 100%;
					.btn{
						margin-bottom: 10px;
						font-size: 12px;
					}
					.social{
						@include flex ( row, flex-end, initial);
						a{
							font-size: 30px;
							margin-right: 10px;
							&:last-child{
								margin-right: 0;
							}
						}
					}
				}
			}
		}

		.bottom{
			@include flex (row, initial, initial);
			margin-bottom: 10px;
			img{
				width: 100%;
				object-fit: contain;
			}
			.columbia-logo{
				flex: 1;
			}
			.listen-btn{
				flex: 1;
				margin: 0 10px;
			}
			.banner{
				flex: 4;
			}
		}
	}

	@media screen and (min-width:680px) {
		.container{
			.top{
				.heading{
					margin-top: 6px;
				}
				.side{
					.inner-top{
						@include flex ( column-reverse, space-between, flex-end);
						position: absolute;
						width: max-content;
						.btn{
							margin-top: 6px;
							margin-bottom: 0; 
							font-size: 11px;
						}
						.social{
							a{
								font-size: 24px;
							}
						}
					}
				}
			}
		}
	}

	@include tablet-up{
		padding-bottom: 0;
		.container{
			.top{
				@include flex (row, space-between, flex-end);
				align-items: flex-end;
				min-height: 170px;
				img.heading{
					max-width: calc(100% - 310px);
				}
				.side{
					max-width: 310px;
					@include flex ( column, initial, flex-end);
					.inner-top{
						@include flex ( row, initial, initial);
						position: initial;
						margin-bottom: 10px;
						.btn{
							margin-right: 10px;
							margin-top: 0;
						}
						.social{
							a{
								font-size: 30px;
							}
						}
					}
					.inner-bottom{
						a{
							img{
								height: 100px;
							}
						}
					}
				}
			}

			.bottom{
				display: block;
				@include flex(row, initial, center);
				.columbia-logo{
					flex: 1;
				}
				.bio{
					flex: 4;
					margin: 0 20px;
				}
				.banner{
					flex: 4;
				}
			}
		}
	}
}

a{
	img{
		transition: all .4s;
		@include hover-focus{
			transform: scale(1.025);
		}
	}
}

main {

	section{
		&.hero{
			position: relative;
			.social{
				display: none;
				gap: 20px;
				justify-items: center;
				padding: 10px 5px 20px;
				position: absolute;
				left: 0;
				top: calc(50% - 97px);
				background-color: $red;
				a{
					display: block;
					img{
						width: 30px;
					}
				}
				span{
					text-transform: uppercase;
					display: block;
					font-size: 14px;
				}
			}
			iframe{
				position: relative;
				z-index: 99999;
			}
			.divider{
				margin: 20px auto;
			}
			@include tablet-up{
				.social{
					display: grid;
				}
			}
		}

		&.cards{
			padding-top: 80px;
			.container{
				.cards-grid{
					display: grid;
					gap: 20px;
					justify-items: center;
				}
			}
			@include phone-up{
				.container{
					.cards-grid{
						grid-template-columns: 1fr 1fr;
					}
				}
			}
			@include tablet-up{
				padding-bottom: 160px;
				.container{
					.cards-grid{
						grid-template-columns: 1fr 1fr 1fr;
						.card{
							display: block;
						}
					}
				}
			}
			@media screen and (min-width:1260px) {
				.container{
					.cards-grid{
						grid-template-columns: 1fr 1fr 1fr 1fr;
					}
				}
			}
		}

		&.video{
			padding: 40px 0;
			@include tablet-up{
				padding: 80px 0;
			}
		}

	}

}

footer {
	padding: 40px 0;
	text-align: center;
	.container{
		max-width: 100%;
	}
	img.logo-columbia-footer{
		display: inline-block;
		width: 80px;
	}
	.logo-link{
		display: block;
		text-align: center;
		font-family: 'Anzeigen Grotesk D';
		color: $red;
		font-size: 22px;
		margin: 20px 0;
	}
	.social{
		@include flex ( row, center, center);
		a{
			margin-right: 5px;
			&:last-child{
				margin-right: 0;
				margin-left: 5px;
			}
			img,i{
				font-size: 30px;
				height: 30px;
			}
		}
	}
	.btn{
		margin: 20px 0;
	}
	.copyright{
		font-size: 12px;
		p{
			margin-bottom: 10px;
		}
	}
	@include tablet-up{
		padding: 200px 0 20px;
		.logo-link{
			font-size: 40px;
			margin: 30px 0;
		}
		.social{
			@include flex ( row, center, center);
			a{
				img,i{
					font-size: 40px;
					height: 40px;
				}
			}
		}
		.btn{
			margin: 30px 0;
		}
	}
}

.card{
	@include tablet-up{
		transition: all .4s;
		@include hover-focus{
			transform: scale(1.05);
		}
	}
}

.card,.card-featured{
	background-color: transparent;
	width: 285px;
	height: 184px;
	perspective: 1000px;
	cursor: pointer;
	.card-inner {
		position: relative;
		width: 100%;
		height: 100%;
		text-align: center;
		transition: transform 1s;
		transform-style: preserve-3d;
		.card-front, .card-back {
			position: absolute;
			width: 100%;
			height: 100%;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
		}
		.card-back {
			transform: rotateY(180deg);
		}
	}
	&.flipped{
		.card-inner {
			transform: rotateY(180deg);
		}
	}
}

.card{
	.card-inner{
		.card-front{
			.card-credit{
				display: none;
			}
		}
	}
}

.card-featured{
	width: calc(100% - 60px);
	height: 322px;
	height: initial;
	aspect-ratio: 16 / 9;
	margin-bottom: 76px;
	.card-inner{
		transition: transform 1.5s;
		.card-credit{
			font-size: 20px;
			margin-top: 20px;
		}
	}
	@include tablet-up{
		.card-inner{
			.card-credit{
				font-size: 30px;
			}
		}
	}
}

.card-popup{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.747);
	z-index: 99999999;
	opacity: 0;
	pointer-events: none;
	transition: all .4s;
	.container{
		height: 100vh;
		@include flex (row, center, center);
		.wrapper{
			@include flex (row, center, center);
			position: relative;
			background-color: $black;
			border: solid 1px rgb(36, 34, 34);
			width: 100%;
			margin-top: 20px;
			// height: calc(100vh - 260px);
			padding: 60px 20px;
			button{
				background-color: transparent;
				border: none;
				padding: 0;
				line-height: 1;
				color: $white;
				font-size: 40px;
				position: absolute;
				&.close{
					top: 20px;
					right: 20px;
				}
				&.prev,&.next{
					bottom: 20px;
					width: 40px;
					height: 40px;
					font-size: 20px;
					@include flex ( row, center, center);
					border: solid 1px $white;
					border-radius: 50%;
				}
				&.prev{
					left: calc(50% - 45px);
					i{
						transform: translateX(-2px);
					}
				}
				&.next{
					right: calc(50% - 45px);
					i{
						transform: translateX(2px);
					}
				}
			}
		}
	}
	&.active{
		opacity: 1;
		pointer-events: all;
	}
	@include tablet-up{
		.container{
			.wrapper{
				height: calc(100vh - 60px);
				padding: 120px 60px;
				button{
					&.prev,&.next{
						top: calc(50% - 20px);
					}
					&.prev{
						left: 10px;
					}
					&.next{
						right: 10px;
					}
				}
			}
		}
	}
}